// Select the button by class name
var buttons = document.querySelectorAll('.js-preloading');

// Add a click event listener to each button
buttons.forEach(function(button) {
    button.addEventListener('click', function() {
        var preloader = document.getElementById('preloader');
        preloader.style.display = 'flex';
    });
});
