document.addEventListener('click', function(e) {
    if(e.target.classList.contains('js-btn-add')) {
        console.log(e);
        let form = document.querySelector('#myRepeatingFields'),
            currentEntry = e.target.closest(".js-products-links"),
            newEntry =currentEntry.cloneNode(true),
            clearInput = newEntry.querySelectorAll('input');

        clearInput.forEach((input) => {
            input.value = "";
        });

        e.target.classList.remove('js-btn-add', 'btn-success')
        e.target.classList.add('js-btn-remove', 'btn-danger');
        e.target.innerHTML = '-';
        form.append(newEntry);
    } else if(e.target.classList.contains('js-btn-remove')) {
        e.target.closest(".js-products-links").remove();
    }
});
